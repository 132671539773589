import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, device } from './layout/GlobalStyles';
import arrowRight from '../images/svgs/arrow-right-black.svg';

const StyledHero = styled.div`
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
        padding: 32px 0px 16px;
        margin: 0;

        padding-left: 0;
        list-style: none;
        li + li {
            position: relative;
            right: 37px;
        }
        li {
            align-items: center;
            justify-content: center;
            img {
                margin: 0 25px 0px 5px;
                width: 7px;
            }
        }
        li:first-of-type,
        .home {
            transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            color: ${colors.gray4};

            &:hover {
                color: ${colors.blue3};
            }
        }
        a:not(.home) {
            font-weight: 600;
            font-size: 16px;

            color: ${colors.blue3};
        }
    }
    h1 {
        margin-top: 0px;
    }
    h1 + div {
        margin: 16px 0px 54px;
        border-radius: 15px;
        height: 200px;
        img {
            height: 200px;
            border-radius: 15px;
        }
    }
    @media ${device.tablet} {
        ul {
            display: flex;
            li + li {
                position: static;
                right: 0%;
            }
        }

        li {
            display: flex;
        }
        margin-top: 32px;
        h1 + div {
            height: 300px;
            img {
                height: 300px;
            }
        }
    }
    @media ${device.laptop} {
        margin-top: 0px;
        h1 + div {
            width: 996px;
            height: 500px;
            img {
                height: 500px;
                width: 996px;
            }
        }
    }
`;

const BreadCrumbs = styled.div``;

interface Props {
    breadCrumbUrls: string[];
    breadCrumbNames: string[];
    title: string;
}

export const Hero: React.FC<Props> = ({ breadCrumbUrls, breadCrumbNames, title, children }) => {
    console.log(breadCrumbNames, breadCrumbUrls);
    return (
        <StyledHero className="container">
            <BreadCrumbs className="flex-container">
                <div>
                    <ul>
                        <li className="first-link">
                            <Link className="home" to="/">
                                Home
                            </Link>
                        </li>
                        {breadCrumbNames.map((name, index) => {
                            return (
                                <li key={name}>
                                    <Link to={breadCrumbUrls[index]}>
                                        <img
                                            style={{ position: 'relative', left: '10px' }}
                                            src={arrowRight}
                                            alt="book appointment"
                                        />
                                        {name}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </BreadCrumbs>
            <h1>{title}</h1>
            {children && <div className="image-shadow">{children}</div>}
        </StyledHero>
    );
};
