import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
const breadCrumbNames = ['Services', 'Counseling'];
const breadCrumbUrls = ['/our-services/', '/Counseling/'];
import { Seo } from '../components/seo';

const Counseling: Page = () => {
    return (
        <>
            <Seo
                title="Innovative Medicine Treatable Medical Illness with Therapies"
                description="We provide medical care for a number of different medical illnesses that we treat with the help of therapies."
            />
            <div className="small-container">
                <Hero
                    title="Counseling"
                    breadCrumbUrls={breadCrumbUrls}
                    breadCrumbNames={breadCrumbNames}
                ></Hero>
                <p>
                    Missy Galbreth is a Licensed Mental Health Counselor (LMHC) with 27 years of
                    experience. She has worked in outpatient settings, inpatient settings,
                    residential and day treatment programs, schools, and in homes. Her counseling
                    experience has been with all ages from 3 years old up. Missy has conducted
                    thousands of group therapy sessions, individual, couples, and family therapy
                    sessions. Although she has had experience with many mental health and behavioral
                    issues, her most recent focus has been on addiction, anxiety, depression, anger,
                    abuse, trauma, and neglect, as well as how they can be related. Missy is very
                    effective in helping people of all ages solve problems and learn coping
                    strategies to help regulate emotions and reduce negative reactions to stress.
                    Currently, she is ready to work with teens and adults in an office setting.
                </p>
            </div>
        </>
    );
};

Counseling.Layout = DefaultLayout;

export default Counseling;
